<template>
  <div class="transactions">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>
        {{ $t('Transactions.xhb1ei') }}
      </span>
      <span></span>
    </header>
    <main class="page-body">
      <div class="search-bar">
        <div class="flexbox align-center">
          <van-search
            v-model="keyword"
            class="round flex-fill"
            :placeholder="$t('Transactions.hufdcs')"
            @search="onSearch"
          ></van-search>
          <van-icon name="filter-o" @click="showPanel = !showPanel" />
        </div>
        <section class="filter-panel" v-show="showPanel">
          <!--<div class="label">{{ $t('Transactions.a75t6k') }}</div>
           <div class="flexbox space-bt tags">
            <div
              class="filter-tag"
              :class="{ active: status === 1 }"
              @click="status = 1"
            >
              作废
            </div>
            <div
              class="filter-tag"
              :class="{ active: status === 2 }"
              @click="status = 2"
            >
              已付
            </div>
            <div
              class="filter-tag"
              :class="{ active: status === 3 }"
              @click="status = 3"
            >
              应付
            </div>
          </div> -->
          <div class="label">{{ $t('Transactions.c11ws6') }}</div>
          <div class="x-center" @click="showCalendar = true">
            {{ dateRange[0] | time('YYYY-MM-DD') }} -
            {{ dateRange[1] | time('YYYY-MM-DD') }}
          </div>
          <div class="search-btn">
            <van-button type="info" @click="onSearch">{{
              $t('Transactions.2g7nzr')
            }}</van-button>
          </div>
        </section>
        <section
          class="overlay"
          @click="showPanel = false"
          v-show="showPanel"
        ></section>
      </div>
      <ul class="list">
        <li class="list-item" v-for="item in transactions" :key="item.PK_ID">
          <header class="item-header">
            <span class="time">{{ item.dCreateTime | time }}</span>
            <span>{{ $t('Transactions.7p7u9f') }}:{{ item.Ssn }}</span>
          </header>
          <main class="item-body flexbox space-bt">
            <span class="amount">￥{{ item.mAmount }}</span>
            <div>
              <div>类型：{{ item.sPayType }}</div>
              <div>订单号：{{ item.sExpNo }}</div>
            </div>
            <div
              class="status-tag"
              :class="['deprecated', 'done', 'due'][item.status - 1]"
            >
              {{ ['作废', '已付', '应付'][item.status - 1] }}
            </div>
          </main>
        </li>
      </ul>
    </main>
    <van-calendar
      :min-date="new Date(2018, 0, 1)"
      :max-date="new Date()"
      v-model="showCalendar"
      type="range"
      @confirm="onConfirm"
    />
  </div>
</template>
<script>
import { getTransactions } from '@/api/money.js';
// import { formatDate } from '@/utils/index.js';
// function getValidTime(value) {
//   if (value == null || value == '') return '';
//   var myDate = new Date(value.match(/\d+/)[0] * 1);
//   return formatDate(myDate);
// }
export default {
  data() {
    const dayOne = new Date();
    dayOne.setDate(dayOne.getDate() - 100);
    return {
      keyword: '',
      status: 1,
      showPanel: false,
      showCalendar: false,
      dateRange: [dayOne, new Date()],
      transactions: [
        // {
        //   id: 1,
        //   date: new Date(),
        //   amount: 50,
        //   no: '123456',
        //   status: 1,
        // },
        // {
        //   id: 2,
        //   date: new Date(),
        //   amount: 50,
        //   no: '123456',
        //   status: 2,
        // },
        // {
        //   id: 3,
        //   date: new Date(),
        //   amount: 50,
        //   no: '123456',
        //   status: 3,
        // },
      ],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getTransactions({
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        keyword: this.keyword,
      }).then(res => {
        this.transactions = res.rows;
      });
    },
    onConfirm(date) {
      this.dateRange = date;
      this.showCalendar = false;
    },
    onSearch() {
      this.showPanel = false;
      this.loadData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.search-bar {
  padding: rem(40px) rem(18px);
  background-color: #fff;
  position: relative;
  overflow: visible;
  .van-field {
    background-color: #f6f7fc;
    border-radius: rem(15px);
    margin-right: rem(27px);
  }
  .van-icon {
    font-size: 24px;
    color: #333;
  }
}
.list-item {
  & + .list-item {
    margin-top: rem(10px);
  }
  padding: rem(20px) rem(40px);
  background-color: #fff;
}
.item-header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding-bottom: rem(20px);
  border-bottom: 1px solid #eaeaea;
}
.item-body {
  padding-top: rem(20px);
  align-items: center;
  .amount {
    font-size: 40px;
  }
}
.status-tag {
  width: rem(180px);
  height: rem(50px);
  line-height: rem(50px);
  border-radius: rem(10px);
  color: #fff;
  text-align: center;
  &.deprecated {
    background-color: #b8b8b8;
  }
  &.done {
    background-color: #4ead09;
  }
  &.due {
    background-color: #dd1b1b;
  }
}
.filter-panel {
  padding: rem(27px) rem(40px);
  padding-bottom: rem(60px);
  position: absolute;
  z-index: 10;
  top: 100%;
  background-color: #fff;
  left: 0;
  right: 0;
  .label {
    margin-bottom: rem(28px);
  }
  .tags {
    margin-bottom: rem(28px);
  }
}
.filter-tag {
  width: rem(180px);
  height: rem(50px);
  line-height: rem(50px);
  text-align: center;
  border-radius: rem(10px);
  background-color: #f6f7fc;
  color: #9c9bb4;
  &.active {
    background-color: $theme-color;
    color: #fff;
  }
}
.search-btn {
  margin-top: rem(90px);
  text-align: center;
  .van-button {
    width: rem(300px);
    border-radius: rem(25px);
  }
}
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(#000, 0.3);
}
</style>
